import { createApp } from "vue/dist/vue.esm-bundler";
import FlashMessage from './components/FlashMessage.vue'
// import AddGiftCertificateToCartForm from './components/AddGiftCertificateToCartForm.vue'
import AddToWishList from './components/AddToWishList.vue'
import BlogCommentSection from './components/BlogCommentSection.vue'
import FilterMenu from './components/FilterMenu.vue'
import InstagramImageGallery from './components/InstagramImageGallery.vue'
import ExtendedProductCard from './components/ExtendedProductCard.vue'
import PaginatedBlogArticleSliderSection from './components/PaginatedBlogArticleSliderSection.vue'
import PaginatedSliderSection from './components/PaginatedSliderSection.vue'
import PaginatedInstagramSliderSection from './components/PaginatedInstagramSliderSection.vue'
// import MyReviewsTable from './components/MyReviewsTable.vue'
import ProductCard from './components/ProductCard.vue'
import ProductCard1 from './components/ProductCard1.vue'
import ProductCard2 from './components/ProductCard2.vue'
import SpecialSection from './components/SpecialSection.vue'
import TestimonialSlider from './components/TestimonialSlider.vue'

import * as Sentry from "@sentry/vue";

const app = createApp({
   components: {
      FlashMessage,
      // AddGiftCertificateToCartForm,
      AddToWishList,
      BlogCommentSection,
      ExtendedProductCard,
      FilterMenu,
      InstagramImageGallery,
      // MyReviewsTable,
      PaginatedBlogArticleSliderSection,
      PaginatedInstagramSliderSection,
      PaginatedSliderSection,
      ProductCard,
      ProductCard1,
      ProductCard2,
      SpecialSection,
      TestimonialSlider,
   }
})
app.config.globalProperties.eventBus = window.eventBus
app.mount('#app');